import {Link} from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import {getAndSetLang, isLangEng, isLangIt} from "../utils/language"

import "../styles/b.sass"

const IndexPage = () => {
  let lang = getAndSetLang("it")

  let content
  let title

  if( isLangIt() ){
      title="Bomboniera"
      content =
      <>
      Se sei arrivato su questa pagina, è perché ti voglio ringraziare. In un modo o in un altro, ti porto nel cuore perché
      durante questi lunghi cinque anni abbiamo condiviso qualcosa e quel qualcosa è unico.
      <br />
      Detto questo, la bomboniera è un gioco!!! L'obbietivo è laurearsi raccogliendo CFU. Mi raccomando! Arriva a 300! 😛
      </>
  }
  if( isLangEng() ){
    title="Confetti"
    content = 
      <>
      If you got here, it is because I want to thank you. In one way or another, you are in my heart because during these
      long five years we shared something which is unique.
      <br />
      Now, you need to know that the party favour is a game!!! The goal is graduate gathering CFU. Pay attention though! Get to 300! 😛
      </>
  }

  return (
    <Layout pageTitle={title} lang={lang}>
      <>
        {content}
        <div className="mainDiv">
        <Link className="secondaryDiv" to="/bomboniera/snake/snake.html">
          <div className="imgDiv" >
            <StaticImage aspectRatio={1} className="staticImg" src="../images/snake.png" />
          </div>
          <p>Snake</p>
        </Link>
        <Link className="secondaryDiv" to="/bomboniera/jumping_salvo/jumping_salvo.html">
          <div className="imgDiv">
            <StaticImage aspectRatio={1} className="staticImg" src="../images/jumping_salvo.png" />
          </div>
          <p>Jumping salvo</p>
        </Link>
        </div>
      </>
    </Layout>
  )
}

export default IndexPage

export const Head = () => <Seo title={"Bomboniera"} />
